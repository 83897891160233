import React from "react";
import { useNavigate } from "react-router-dom";

const NeedJobToast = () => {
  const navigate = useNavigate();

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-23bb663 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="23bb663"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-339db90"
          data-id="339db90"
          data-element_type="column"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-2ec7f76 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="2ec7f76"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-no">
                <div
                  className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2175b51"
                  data-id="2175b51"
                  data-element_type="column"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-widget-wrap">
                    <div className="elementor-background-overlay" />
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-83372ab"
                  data-id="83372ab"
                  data-element_type="column"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-widget-wrap">
                    <div className="elementor-background-overlay" />
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d55169b"
                  data-id="d55169b"
                  data-element_type="column"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-79e4a89 elementor-widget elementor-widget-heading"
                      data-id="79e4a89"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h3 className="elementor-heading-title elementor-size-default">
                          Need a Job?
                        </h3>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-1a7d1aa elementor-mobile-align-left elementor-widget elementor-widget-button"
                      data-id="1a7d1aa"
                      data-element_type="widget"
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-button-wrapper">
                          <a
                            onClick={() => navigate("/join-us-form")}
                            className="elementor-button-link elementor-button elementor-size-sm"
                            role="button"
                          >
                            <span className="elementor-button-content-wrapper">
                              <span className="elementor-button-text">
                                Click Here
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NeedJobToast;
