import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  return (
    <header
      className="site-header header-main-layout-1 ast-primary-menu-enabled ast-hide-custom-menu-mobile ast-builder-menu-toggle-icon ast-mobile-header-inline"
      id="masthead"
      itemType="https://schema.org/WPHeader"
      itemScope="itemscope"
      itemID="#masthead"
    >
      <div id="ast-desktop-header" data-toggle-type="dropdown">
        <div className="ast-main-header-wrap main-header-bar-wrap">
          <div
            className="ast-primary-header-bar ast-primary-header main-header-bar site-header-focus-item"
            data-section="section-primary-header-builder"
          >
            <div
              className="site-primary-header-wrap ast-builder-grid-row-container site-header-focus-item ast-container"
              data-section="section-primary-header-builder"
              style={{
                maxWidth: "1240px",
              }}
            >
              <div className="ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center">
                <div className="site-header-primary-section-left site-header-section ast-flex site-header-section-left">
                  <div
                    className="ast-builder-layout-element ast-flex site-header-focus-item"
                    data-section="title_tagline"
                  >
                    <div
                      className="site-branding ast-site-identity"
                      itemType="https://schema.org/Organization"
                      itemScope="itemscope"
                    >
                      <span className="site-logo-img">
                        <a
                          onClick={() => navigate("/")}
                          className="custom-logo-link transparent-custom-logo"
                          rel="home"
                          itemProp="url"
                          aria-label="curbit.us"
                        >
                          <img
                            src="assets/white-version-1-163x163.png"
                            className="custom-logo"
                            alt=""
                            decoding="async"
                            loading="lazy"
                            srcSet="
                            assets/white-version-1-163x163.png    163w,
                            assets/white-version-1-300x300.png    300w,
                            assets/white-version-1-1024x1024.png 1024w,
                            assets/white-version-1-150x150.png    150w,
                            assets/white-version-1-768x768.png    768w,
                            assets/white-version-1-1536x1536.png 1536w,
                            assets/white-version-1-2048x2048.png 2048w
                          "
                            sizes="(max-width: 163px) 100vw, 163px"
                            width="163"
                            height="163"
                          />
                        </a>
                        <a
                          href="https://curbit.us/"
                          className="custom-logo-link ast-transparent-mobile-logo"
                          rel="home"
                          itemProp="url"
                        ></a>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="site-header-primary-section-right site-header-section ast-flex ast-grid-right-section">
                  <div
                    className="ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item"
                    data-section="section-hb-menu-1"
                  >
                    <div className="ast-main-header-bar-alignment">
                      <div className="main-header-bar-navigation">
                        <nav
                          className="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item"
                          id="primary-site-navigation"
                          aria-label="Site Navigation"
                          itemType="https://schema.org/SiteNavigationElement"
                          itemScope="itemscope"
                        >
                          <div className="main-navigation ast-inline-flex">
                            <ul
                              id="ast-hf-menu-1"
                              className="main-header-menu ast-menu-shadow ast-nav-menu ast-flex submenu-with-border stack-on-mobile"
                              aria-expanded="false"
                            >
                              <li
                                id="menu-item-1082"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1081 current_page_item menu-item-1082"
                              >
                                <a
                                  onClick={() => navigate("/")}
                                  aria-current="page"
                                  className="menu-link"
                                >
                                  Home
                                </a>
                              </li>
                              <li
                                id="menu-item-15"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15"
                              >
                                <a href="#service" className="menu-link">
                                  Services
                                </a>
                              </li>
                              <li
                                id="menu-item-16"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16"
                              >
                                <a href="#reviews" className="menu-link">
                                  Testimonials
                                </a>
                              </li>
                              <li
                                id="menu-item-14"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-14"
                              >
                                <a href="#about" className="menu-link">
                                  Our Mission
                                </a>
                              </li>
                              <li
                                id="menu-item-1131"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1131"
                              >
                                <a href="#about.us" className="menu-link">
                                  About Us
                                </a>
                              </li>
                              <li
                                id="menu-item-17"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-17"
                              >
                                <a href="#contact_us" className="menu-link">
                                  Contact Us
                                </a>
                              </li>
                              <li
                                id="menu-item-1604"
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1604"
                              >
                                <a
                                  onClick={() => navigate("/join-us-form")}
                                  className="menu-link"
                                >
                                  Join Us
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ast-builder-layout-element ast-flex site-header-focus-item ast-header-button-1"
                    data-section="section-hb-button-1"
                  >
                    <div className="ast-builder-button-wrap ast-builder-button-size-">
                      <a
                        className="ast-custom-button-link"
                        href="tel:5035286342"
                        target="_self"
                      >
                        <div
                          className="ast-custom-button"
                          style={{
                            padding: ".8em",
                          }}
                        >
                          Call: 503-528-6342
                        </div>
                      </a>
                      <a
                        className="menu-link"
                        href="tel:5035286342"
                        target="_self"
                        style={{
                          padding: ".8em",
                        }}
                      >
                        Call: 503-528-6342
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ast-desktop-header-content content-align-flex-start"></div>
      </div>

      <div
        id="ast-mobile-header"
        className="ast-mobile-header-wrap"
        data-type="dropdown"
      >
        <div className="ast-main-header-wrap main-header-bar-wrap">
          <div
            className="ast-primary-header-bar ast-primary-header main-header-bar site-primary-header-wrap site-header-focus-item ast-builder-grid-row-layout-default ast-builder-grid-row-tablet-layout-default ast-builder-grid-row-mobile-layout-default"
            data-section="section-transparent-header"
          >
            <div className="ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center">
              <div className="site-header-primary-section-left site-header-section ast-flex site-header-section-left">
                <div
                  className="ast-builder-layout-element ast-flex site-header-focus-item"
                  data-section="title_tagline"
                >
                  <div
                    className="site-branding ast-site-identity"
                    itemType="https://schema.org/Organization"
                    itemScope="itemscope"
                  >
                    <span className="site-logo-img">
                      <a
                        href="https://curbit.us/"
                        className="custom-logo-link transparent-custom-logo"
                        rel="home"
                        itemProp="url"
                        aria-label="curbit.us"
                      >
                        <img
                          src="assets/white-version-1-163x163.png"
                          className="custom-logo"
                          alt=""
                          decoding="async"
                          loading="lazy"
                          sizes="(max-width: 163px) 100vw, 163px"
                          width="163"
                          height="163"
                        />
                      </a>
                      <a
                        href="https://curbit.us/"
                        className="custom-logo-link ast-transparent-mobile-logo"
                        rel="home"
                        itemProp="url"
                      ></a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="site-header-primary-section-right site-header-section ast-flex ast-grid-right-section">
                <div
                  className="ast-builder-layout-element ast-flex site-header-focus-item ast-header-button-1"
                  data-section="section-hb-button-1"
                >
                  <div className="ast-builder-button-wrap ast-builder-button-size-">
                    <a
                      className="ast-custom-button-link"
                      href="tel:5035286342"
                      target="_self"
                    >
                      <div
                        className="ast-custom-button"
                        style={{
                          padding: ".8em",
                        }}
                      >
                        Call: 503-528-6342
                      </div>
                    </a>
                    <a
                      className="menu-link"
                      href="tel:5035286342"
                      target="_self"
                      style={{
                        padding: ".8em",
                      }}
                    >
                      Call: 503-528-6342
                    </a>
                  </div>
                </div>
                <div
                  className="ast-builder-layout-element ast-flex site-header-focus-item"
                  data-section="section-header-mobile-trigger"
                >
                  <div className="ast-button-wrap">
                    <button
                      type="button"
                      className={`menu-toggle main-header-menu-toggle ast-mobile-menu-trigger-fill ${
                        open && "toggled"
                      }`}
                      aria-expanded="false"
                      data-index="0"
                      onClick={() => setOpen(!open)}
                    >
                      <span className="screen-reader-text">Main Menu</span>
                      <span className="mobile-menu-toggle-icon">
                        <span className="ahfb-svg-iconset ast-inline-flex svg-baseline">
                          <svg
                            className="ast-mobile-svg ast-menu-svg"
                            fill="currentColor"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M3 13h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 7h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1zM3 19h18c0.552 0 1-0.448 1-1s-0.448-1-1-1h-18c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path>
                          </svg>
                        </span>
                        <span className="ahfb-svg-iconset ast-inline-flex svg-baseline">
                          <svg
                            className="ast-mobile-svg ast-close-svg"
                            fill="currentColor"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ast-mobile-header-content content-align-flex-start"
          style={{
            display: open && "inline",
          }}
        >
          <div
            className="ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item"
            data-section="section-hb-menu-1"
          >
            <nav
              className="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item"
              id="primary-site-navigation"
              aria-label="Site Navigation"
              itemType="https://schema.org/SiteNavigationElement"
              itemScope="itemscope"
            >
              <ul className="mobile-header">
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1081 current_page_item menu-item-1082">
                  <a
                    onClick={() => navigate("/")}
                    aria-current="page"
                    className="menu-link"
                  >
                    Home
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15">
                  <a
                    onClick={() => navigate("/#service")}
                    className="menu-link"
                  >
                    Services
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16">
                  <a href="#reviews" className="menu-link">
                    Testimonials
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-14">
                  <a href="#about" className="menu-link">
                    Our Mission
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1131">
                  <a href="#about.us" className="menu-link">
                    About Us
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-17">
                  <a href="#contact_us" className="menu-link">
                    Contact Us
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1604">
                  <a
                    onClick={() => navigate("/join-us-form")}
                    className="menu-link"
                  >
                    Join Us
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
