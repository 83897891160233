import React from "react";

const Services = () => {
  return       <section
  className="elementor-section elementor-top-section elementor-element elementor-element-42820ed elementor-section-boxed elementor-section-height-default elementor-section-height-default"
  data-id="42820ed"
  data-element_type="section"
  id="service"
  data-settings='{"background_background":"classic"}'
>
  <div className="elementor-background-overlay" />
  <div className="elementor-container elementor-column-gap-no">
    <div
      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0c6f11c"
      data-id="0c6f11c"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-3493b0f elementor-widget elementor-widget-heading"
          data-id="3493b0f"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <h2 className="elementor-heading-title elementor-size-default">
              Our Services
            </h2>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-9ea0454 elementor-widget elementor-widget-heading"
          data-id="9ea0454"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <h6 className="elementor-heading-title elementor-size-default">
              Why you should have your address displayed on
              your curb
            </h6>
          </div>
        </div>
        <section
          className="elementor-section elementor-inner-section elementor-element elementor-element-cd7607f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="cd7607f"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d8a0793"
              data-id="d8a0793"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-5c71411 elementor-widget elementor-widget-heading"
                  data-id="5c71411"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Emergency Services
                    </h2>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-bbe54c1 elementor-widget elementor-widget-text-editor"
                  data-id="bbe54c1"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Everyone should have their numbers
                      displayed on their curb for
                      identification purposes so emergency
                      services like firetrucks and ambulances
                      can find your home easier in the case of
                      an emergency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6f942af"
              data-id="6f942af"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-80df78e elementor-widget elementor-widget-heading"
                  data-id="80df78e"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Convenience
                    </h2>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-e6821d2 elementor-widget elementor-widget-text-editor"
                  data-id="e6821d2"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Everyone from the pizza delivery
                        driver to the mailman will appreciate
                        having your house numbers displayed on
                        your curb for easy identification.
                        Having your house numbers on your curb
                        will ensure no more wrong deliveries
                        of your anticipated packages or your
                        beloved pizza.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
};

export default Services;
