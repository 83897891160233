import React, { useState } from "react";
import emailjs, { send } from "emailjs-com";
import Footer from "../Footer";
import Header from "../Header";

const JoinUsForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    education: "",
    minor: undefined,
    lookingFor: "",
    availableNextWeek: undefined,
    opentoSale: undefined,
    handleRejection: "",
    intext: "",
    referredBy: "",
    hearAboutUs: "",
  });

  const [sending, setSending] = useState(false);
  const [alertContent, setAlertContent] = useState();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    emailjs
      .send(
        "service_4a8y0w2",
        "template_rwo2b0s",
        formData,
        "_Mk2HlHQIICi4vKpO"
      )
      .then(
        (result) => {
          setFormData({
            email: "",
            firstName: "",
            lastName: "",
            subject: "",
            message: "",
          });
          setAlertContent({
            heading: " Don't hesitate to ask.",
            message:
              " Thanks for contacting us! We will be in touch with you shortly.",
          });
          setSending(false);
        },
        (error) => {
          console.log(error);
          setAlertContent({
            heading: "Something went wrong.",
            message: error.text,
          });
          setSending(false);
        }
      );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Header />

      <div id="content" className="site-content">
        <div className="ast-container">
          <div id="primary" className="content-area primary">
            <main id="main" className="site-main">
              <article
                className="post-1519 page type-page status-publish ast-article-single"
                id="post-1519"
                itemType="https://schema.org/CreativeWork"
                itemScope="itemscope"
              >
                <header className="entry-header ast-header-without-markup" />
                <div className="entry-content clear" itemProp="text">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id={1519}
                    className="elementor elementor-1519"
                  >
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-2a54hgv elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="2a54hgv"
                      data-element_type="section"
                      data-settings='{"background_background":"classic","shape_divider_bottom":"curve","shape_divider_bottom_negative":"yes"}'
                    >
                      <div className="elementor-background-overlay" />
                      <div
                        className="elementor-shape elementor-shape-bottom"
                        data-negative="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1000 100"
                          preserveAspectRatio="none"
                        >
                          <path
                            className="elementor-shape-fill"
                            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
                          />
                        </svg>
                      </div>
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2005caa"
                          data-id="2005caa"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-429ccfb elementor-widget elementor-widget-heading"
                              data-id="429ccfb"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h1
                                  className="elementor-heading-title elementor-size-xl"
                                  style={{
                                    marginTop: "1.2em",
                                    paddingBottom: "1.5em",
                                  }}
                                >
                                  Sales representative job application
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-c5229ea elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="c5229ea"
                      data-element_type="section"
                      data-settings='{"background_background":"slideshow","background_slideshow_gallery":[],"background_slideshow_loop":"yes","background_slideshow_slide_duration":5000,"background_slideshow_slide_transition":"fade","background_slideshow_transition_duration":500}'
                    >
                      <div
                        className="elementor-background-slideshow swiper-container"
                        dir="rtl"
                      >
                        <div className="swiper-wrapper" />
                      </div>
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ee305c8"
                          data-id="ee305c8"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-3fe0d29 elementor-widget elementor-widget-text-editor"
                              data-id="3fe0d29"
                              data-element_type="widget"
                              data-widget_type="text-editor.default"
                            >
                              <div className="elementor-widget-container">
                                <p
                                  style={{
                                    marginTop: "1.5em",
                                  }}
                                >
                                  Looking for a summer job? We are filling
                                  full-time and part-time work in the Portland
                                  area on a first come first serve basis. Our
                                  entry level sales positions start at $25 base
                                  pay with opportunities to earn more based on
                                  performance. Get paid weekly and work at your
                                  own pace! No experience is necessary and
                                  training is provided.
                                  <br />
                                  <br />
                                  Fill out the information on this form and a
                                  recruiting manager will get in contact with
                                  you to schedule an interview and tell you more
                                  about available positions!
                                  <br />
                                  <br />
                                  Thank you and good luck
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-3329bd8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="3329bd8"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fd5f32e"
                          data-id="fd5f32e"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-6c2c1f4 elementor-widget elementor-widget-wpforms"
                              data-id="6c2c1f4"
                              data-element_type="widget"
                              data-widget_type="wpforms.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  className="wpforms-container"
                                  id="wpforms-1514"
                                >
                                  {alertContent ? (
                                    <p>
                                      Thanks for contacting us! We will be in
                                      touch with you shortly.
                                    </p>
                                  ) : (
                                    <form
                                      className="wpforms-validate wpforms-form wpforms-ajax-form"
                                      onSubmit={handleOnSubmit}
                                    >
                                      <div className="wpforms-field-container">
                                        <div
                                          id="wpforms-1514-field_1-container"
                                          className="wpforms-field wpforms-field-name"
                                          data-field-id={1}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_1"
                                          >
                                            Enter Your Name
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <div className="wpforms-field-row wpforms-field-medium">
                                            <div className="wpforms-field-row-block wpforms-first wpforms-one-half">
                                              <input
                                                type="text"
                                                id="wpforms-1514-field_1"
                                                className="wpforms-field-name-first wpforms-field-required"
                                                value={formData.firstName}
                                                name="firstName"
                                                onChange={handleChange}
                                                required
                                              />
                                              <label
                                                htmlFor="wpforms-1514-field_1"
                                                className="wpforms-field-sublabel after"
                                              >
                                                First
                                              </label>
                                            </div>
                                            <div className="wpforms-field-row-block wpforms-one-half">
                                              <input
                                                type="text"
                                                id="wpforms-1514-field_1-last"
                                                className="wpforms-field-name-last wpforms-field-required"
                                                value={formData.lastName}
                                                name="lastName"
                                                onChange={handleChange}
                                                required
                                              />
                                              <label
                                                htmlFor="wpforms-1514-field_1-last"
                                                className="wpforms-field-sublabel after"
                                              >
                                                Last
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          id="wpforms-1514-field_2-container"
                                          className="wpforms-field wpforms-field-email"
                                          data-field-id={2}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_2"
                                          >
                                            Email
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="email"
                                            id="wpforms-1514-field_2"
                                            className="wpforms-field-medium wpforms-field-required"
                                            value={formData.email}
                                            name="email"
                                            onChange={handleChange}
                                            required
                                          />
                                        </div>
                                        <div
                                          id="wpforms-1514-field_3-container"
                                          className="wpforms-field wpforms-field-text"
                                          data-field-id={3}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_3"
                                          >
                                            Phone Number
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            id="wpforms-1514-field_3"
                                            className="wpforms-field-medium wpforms-field-required"
                                            value={formData.phone}
                                            name="phone"
                                            onChange={handleChange}
                                            required
                                          />
                                        </div>
                                        <div
                                          id="wpforms-1514-field_11-container"
                                          className="wpforms-field wpforms-field-select wpforms-field-select-style-classic"
                                          data-field-id={11}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_11"
                                          >
                                            Educational Background:
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            id="wpforms-1514-field_4"
                                            className="wpforms-field-medium wpforms-field-required choicesjs-select choices__input choices__input--hidden"
                                            value={formData.education}
                                            name="education"
                                            onChange={handleChange}
                                            required="required"
                                          >
                                            <option
                                              className="placeholder"
                                              selected
                                            >
                                              Choose an option
                                            </option>
                                            <option
                                              value="Graduated High School"
                                              className="placeholder"
                                            >
                                              Graduated High School
                                            </option>
                                            <option
                                              value="Some College"
                                              className="placeholder"
                                            >
                                              Some College
                                            </option>
                                            <option
                                              value="College Student"
                                              className="placeholder"
                                            >
                                              College Student
                                            </option>
                                            <option
                                              value="College Graduate"
                                              className="placeholder"
                                            >
                                              College Graduate
                                            </option>
                                            <option
                                              value="Other"
                                              className="placeholder"
                                            >
                                              Other
                                            </option>
                                          </select>
                                        </div>
                                        <div
                                          id="wpforms-1514-field_9-container"
                                          className="wpforms-field wpforms-field-radio"
                                          data-field-id={9}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_9"
                                          >
                                            Are you at least 18 or turning 18
                                            within the next 30 days?
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <ul
                                            id="wpforms-1514-field_9"
                                            className="wpforms-field-required"
                                          >
                                            <li className="choice-1 depth-1">
                                              <input
                                                type="radio"
                                                id="wpforms-1514-field_9_1"
                                                checked={formData.minor}
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    minor: true,
                                                  });
                                                }}
                                                required
                                              />
                                              <label
                                                className="wpforms-field-label-inline"
                                                htmlFor="wpforms-1514-field_9_1"
                                              >
                                                YES
                                              </label>
                                            </li>
                                            <li className="choice-2 depth-1">
                                              <input
                                                type="radio"
                                                checked={
                                                  formData.minor === false
                                                }
                                                required
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    minor: false,
                                                  });
                                                }}
                                              />
                                              <label
                                                className="wpforms-field-label-inline"
                                                htmlFor="wpforms-1514-field_9_2"
                                              >
                                                NO
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          id="wpforms-1514-field_11-container"
                                          className="wpforms-field wpforms-field-select wpforms-field-select-style-classic"
                                          data-field-id={11}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_11"
                                          >
                                            Looking For:
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            id="wpforms-1514-field_11"
                                            className="wpforms-field-medium wpforms-field-required"
                                            value={formData.lookingFor}
                                            name="lookingFor"
                                            onChange={handleChange}
                                            required="required"
                                          >
                                            <option selected>
                                              Choose an option
                                            </option>
                                            <option value="Part Time Work">
                                              Part Time Work
                                            </option>
                                            <option value="Full Time Work">
                                              Full Time Work
                                            </option>
                                            <option value="Either">
                                              Either
                                            </option>
                                          </select>
                                        </div>
                                        <div
                                          id="wpforms-1514-field_6-container"
                                          className="wpforms-field wpforms-field-radio"
                                          data-field-id={6}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_6"
                                          >
                                            Are you available to start next
                                            week?
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <ul
                                            id="wpforms-1514-field_6"
                                            className="wpforms-field-required"
                                          >
                                            <li className="choice-1 depth-1">
                                              <input
                                                type="radio"
                                                id="wpforms-1514-field_6_1"
                                                checked={
                                                  formData.availableNextWeek
                                                }
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    availableNextWeek: true,
                                                  });
                                                }}
                                                required
                                              />
                                              <label
                                                className="wpforms-field-label-inline"
                                                htmlFor="wpforms-1514-field_6_1"
                                              >
                                                YES
                                              </label>
                                            </li>
                                            <li className="choice-2 depth-1">
                                              <input
                                                type="radio"
                                                id="wpforms-1514-field_6_2"
                                                checked={
                                                  formData.availableNextWeek ===
                                                  false
                                                }
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    availableNextWeek: false,
                                                  });
                                                }}
                                                required
                                              />
                                              <label
                                                className="wpforms-field-label-inline"
                                                htmlFor="wpforms-1514-field_6_2"
                                              >
                                                NO
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          id="wpforms-1514-field_8-container"
                                          className="wpforms-field wpforms-field-radio"
                                          data-field-id={8}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_8"
                                          >
                                            Are you open to doing sales?
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <ul
                                            id="wpforms-1514-field_8"
                                            className="wpforms-field-required"
                                          >
                                            <li className="choice-2 depth-1">
                                              <input
                                                type="radio"
                                                id="wpforms-1514-field_8_2"
                                                checked={formData.opentoSale}
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    opentoSale: true,
                                                  });
                                                }}
                                                required
                                              />
                                              <label
                                                className="wpforms-field-label-inline"
                                                htmlFor="wpforms-1514-field_8_2"
                                              >
                                                YES
                                              </label>
                                            </li>
                                            <li className="choice-3 depth-1">
                                              <input
                                                type="radio"
                                                id="wpforms-1514-field_8_3"
                                                checked={
                                                  formData.opentoSale === false
                                                }
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    opentoSale: false,
                                                  });
                                                }}
                                                required
                                              />
                                              <label
                                                className="wpforms-field-label-inline"
                                                htmlFor="wpforms-1514-field_8_3"
                                              >
                                                NO
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          id="wpforms-1514-field_12-container"
                                          className="wpforms-field wpforms-field-select wpforms-field-select-style-classic"
                                          data-field-id={12}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_12"
                                          >
                                            How do you handle rejection?
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            id="wpforms-1514-field_12"
                                            className="wpforms-field-medium wpforms-field-required"
                                            value={formData.handleRejection}
                                            name="handleRejection"
                                            onChange={handleChange}
                                            required="required"
                                          >
                                            <option selected>
                                              Choose an option
                                            </option>
                                            <option value="Very well">
                                              Very well
                                            </option>
                                            <option value="Well">Well</option>
                                            <option value="Neutral">
                                              Neutral
                                            </option>
                                            <option value="Not well">
                                              Not well
                                            </option>
                                            <option value="Hate it ">
                                              Hate it
                                            </option>
                                          </select>
                                        </div>
                                        <div
                                          id="wpforms-1514-field_7-container"
                                          className="wpforms-field wpforms-field-radio"
                                          data-field-id={7}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_7"
                                          >
                                            Are you introverted or extroverted?
                                            <span className="wpforms-required-label">
                                              *
                                            </span>
                                          </label>
                                          <ul
                                            id="wpforms-1514-field_7"
                                            className="wpforms-field-required"
                                          >
                                            <li className="choice-1 depth-1">
                                              <input
                                                type="radio"
                                                checked={
                                                  formData.intext ===
                                                  "Introverted"
                                                }
                                                required
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    intext: "Introverted",
                                                  });
                                                }}
                                              />
                                              <label
                                                className="wpforms-field-label-inline"
                                                htmlFor="wpforms-1514-field_7_1"
                                              >
                                                Introverted
                                              </label>
                                            </li>
                                            <li className="choice-2 depth-1">
                                              <input
                                                type="radio"
                                                checked={
                                                  formData.intext ===
                                                  "Extroverted"
                                                }
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    intext: "Extroverted",
                                                  });
                                                }}
                                                required
                                              />
                                              <label
                                                className="wpforms-field-label-inline"
                                                htmlFor="wpforms-1514-field_7_2"
                                              >
                                                Extroverted
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          id="wpforms-1514-field_13-container"
                                          className="wpforms-field wpforms-field-text"
                                          data-field-id={13}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_13"
                                          >
                                            Who referred you?
                                          </label>
                                          <input
                                            type="text"
                                            className="wpforms-field-medium"
                                            name="referredBy"
                                            value={formData.referredBy}
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <div
                                          id="wpforms-1514-field_14-container"
                                          className="wpforms-field wpforms-field-text"
                                          data-field-id={14}
                                        >
                                          <label
                                            className="wpforms-field-label"
                                            htmlFor="wpforms-1514-field_14"
                                          >
                                            How did you hear about us?
                                          </label>
                                          <input
                                            type="text"
                                            id="wpforms-1514-field_14"
                                            className="wpforms-field-medium"
                                            name="hearAboutUs"
                                            value={formData.hearAboutUs}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="wpforms-submit-container">
                                        <input
                                          type="hidden"
                                          name="wpforms[id]"
                                          defaultValue={1514}
                                        />
                                        <input
                                          type="hidden"
                                          name="wpforms[author]"
                                          defaultValue={1}
                                        />
                                        <input
                                          type="hidden"
                                          name="wpforms[post_id]"
                                          defaultValue={1519}
                                        />
                                        <button
                                          type="submit"
                                          disabled={
                                            formData.firstName === "" ||
                                            formData.lastName === "" ||
                                            formData.email === "" ||
                                            formData.phone === "" ||
                                            formData.handleRejection === "" ||
                                            formData.lookingFor === "" ||
                                            formData.education === "" ||
                                            formData.sending
                                          }
                                          style={{
                                            cursor:
                                              formData.firstName === "" ||
                                              formData.lastName === "" ||
                                              formData.email === "" ||
                                              formData.phone === "" ||
                                              formData.handleRejection === "" ||
                                              formData.lookingFor === "" ||
                                              formData.education === "" ||
                                              sending
                                                ? "no-drop"
                                                : "auto",
                                            "&:hover": {
                                              color: "#000",
                                            },
                                          }}
                                        >
                                          {sending ? "Sending..." : "Submit"}
                                        </button>
                                        <img
                                          decoding="async"
                                          src="assets/submit-spin.svg"
                                          className="wpforms-submit-spinner"
                                          style={{ display: "none" }}
                                          width={26}
                                          height={26}
                                          alt="Loading"
                                        />
                                      </div>
                                    </form>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JoinUsForm;
