import React, { useState } from "react";
import emailjs from "emailjs-com";

const GiveUsChance = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    address: "",
  });
  const [sending, setSending] = useState(false);
  const [alertContent, setAlertContent] = useState();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    emailjs
      .send(
        "service_4a8y0w2",
        "template_1hldmma",
        formData,
        "_Mk2HlHQIICi4vKpO"
      )
      .then(
        (result) => {
          setFormData({
            firstName: "",
            lastName: "",
            contact: "",
            address: "",
          });
          setAlertContent({
            heading: " Don't hesitate to ask.",
            message:
              "Thanks for contacting us! We will be in touch with you shortly.",
          });
          setSending(false);
        },
        (error) => {
          console.log(error);
          setAlertContent({
            heading: "Something went wrong.",
            message: error.text,
          });
          setSending(false);
        }
      );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-406c699 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="406c699"
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9386697"
          data-id={9386697}
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-7030634 elementor-widget elementor-widget-heading give_us_chance"
              data-id={7030634}
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Give Us a Chance
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-1d184df elementor-widget elementor-widget-text-editor"
              data-id="1d184df"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>
                  Everyone should have their numbers displayed on their curb for
                  identification purposes so emergency services like firetrucks
                  and ambulances can find your home easier in the case of an
                  emergency.
                </p>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-c3a2a5f elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="c3a2a5f"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <link rel="stylesheet" href="assets/widget-icon-list.min.css" />
                <ul
                  className="elementor-icon-list-items"
                  style={{
                    margin: "0 0 1.5em 0em",
                  }}
                >
                  <li
                    className="elementor-icon-list-item"
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <span
                      className="elementor-icon-list-icon"
                      style={{
                        paddingRight: ".2em",
                      }}
                    >
                      <i aria-hidden="true" className="fas fa-phone" />
                    </span>
                    <span className="elementor-icon-list-text">Call today</span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-afd7edf elementor-widget elementor-widget-heading"
              data-id="afd7edf"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  503-528-6342
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7d0aa82"
          data-id="7d0aa82"
          data-element_type="column"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-30b2432 elementor-widget elementor-widget-shortcode"
              data-id="30b2432"
              data-element_type="widget"
              data-widget_type="shortcode.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-shortcode">
                  <div className="wpforms-container" id="wpforms-1462">
                    {alertContent ? (
                      <>
                        <div class="elementor-shortcode">
                          <div class="wpforms-container " id="wpforms-10">
                            <div
                              class="wpforms-confirmation-container wpforms-confirmation-scroll"
                              id="wpforms-confirmation-10"
                            >
                              <p>{alertContent?.message}</p>
                            </div>
                          </div>{" "}
                        </div>
                      </>
                    ) : (
                      <form
                        onSubmit={handleOnSubmit}
                        className="wpforms-validate wpforms-form wpforms-ajax-form"
                      >
                        <noscript className="wpforms-error-noscript">
                          Please enable JavaScript in your browser to complete
                          this form.
                        </noscript>
                        <div className="wpforms-field-container">
                          <div
                            id="wpforms-1462-field_1-container"
                            className="wpforms-field wpforms-field-name"
                            data-field-id={1}
                          >
                            <label
                              className="wpforms-field-label"
                              htmlFor="wpforms-1462-field_1"
                            >
                              Name
                              <span className="wpforms-required-label">*</span>
                            </label>
                            <div className="wpforms-field-row wpforms-field-medium">
                              <div className="wpforms-field-row-block wpforms-first wpforms-one-half">
                                <input
                                  type="text"
                                  id="wpforms-1462-field_1"
                                  className="wpforms-field-name-first wpforms-field-required"
                                  name="firstName"
                                  value={formData.firstName}
                                  onChange={handleChange}
                                  required
                                />
                                <label
                                  htmlFor="wpforms-1462-field_1"
                                  className="wpforms-field-sublabel after"
                                >
                                  First
                                </label>
                              </div>
                              <div className="wpforms-field-row-block wpforms-one-half">
                                <input
                                  type="text"
                                  id="wpforms-1462-field_1-last"
                                  className="wpforms-field-name-last wpforms-field-required"
                                  name="lastName"
                                  value={formData.lastName}
                                  onChange={handleChange}
                                  required
                                />
                                <label
                                  htmlFor="wpforms-1462-field_1-last"
                                  className="wpforms-field-sublabel after"
                                >
                                  Last
                                </label>
                              </div>
                            </div>
                          </div>
                          <div
                            id="wpforms-1462-field_2-container"
                            className="wpforms-field wpforms-field-text"
                            data-field-id={2}
                          >
                            <label
                              className="wpforms-field-label"
                              htmlFor="wpforms-1462-field_2"
                            >
                              Contact number
                              <span className="wpforms-required-label">*</span>
                            </label>
                            <input
                              type="text"
                              id="wpforms-1462-field_2"
                              className="wpforms-field-medium wpforms-field-required"
                              name="contact"
                              value={formData.contact}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div
                            id="wpforms-1462-field_3-container"
                            className="wpforms-field wpforms-field-text"
                            data-field-id={3}
                          >
                            <label
                              className="wpforms-field-label"
                              htmlFor="wpforms-1462-field_3"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              id="wpforms-1462-field_3"
                              className="wpforms-field-medium"
                              name="wpforms[fields][3]"
                            />
                            <div className="wpforms-field-description">
                              (optional)
                            </div>
                          </div>
                        </div>
                        <div className="wpforms-submit-container">
                          <button
                            type="submit"
                            className="wpforms-submit"
                            disabled={
                              formData.firstName === "" ||
                              formData.lastName === "" ||
                              formData.contact === "" ||
                              sending
                            }
                            style={{
                              cursor:
                                formData.firstName === "" ||
                                formData.lastName === "" ||
                                formData.contact === "" ||
                                sending
                                  ? "no-drop"
                                  : "auto",
                            }}
                          >
                            {sending ? "Sending..." : "Submit"}
                          </button>
                          <img
                            decoding="async"
                            src="assets/submit-spin.svg"
                            className="wpforms-submit-spinner"
                            style={{ display: "none" }}
                            alt="Loading"
                            width={26}
                            height={26}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiveUsChance;
