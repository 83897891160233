import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import About from "./Components/About";
import JoinUsForm from "./Components/JoinUsForm";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/join-us-form",
    element: <JoinUsForm />,
  },
  {
    path: "/about-us",
    element: <About />,
  },
]);
