import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";

import SwiperCore, { Autoplay, Pagination } from "swiper/core";

SwiperCore.use([Pagination, Autoplay]);

export default function CustomSwiper() {
  return (
    <>
      <Swiper
        slidesPerView={4}
        centeredSlides={true}
        spaceBetween={2}
        pagination={{
          type: "bullets",
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        style={{
          overflow: "hidden",
        }}
        className="mySwiper"
      >
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-03-3-08-22-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/WhatsApp-Image-2022-03-10-at-1.20.29-PM-e1646980693756.jpeg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/WhatsApp-Image-2022-03-13-at-3.57.00-AM-e1647327302406.jpeg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/WhatsApp-Image-2022-03-13-at-3.56.22-AM-e1647327328869.jpeg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/WhatsApp-Image-2022-03-03-at-6.00.27-AM-e1646629053562.jpeg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-07-2-29-06-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-07-2-49-38-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-07-4-24-25-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-07-1-54-39-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-03-3-08-22-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/WhatsApp-Image-2022-03-10-at-1.20.29-PM-e1646980693756.jpeg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100% " }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/WhatsApp-Image-2022-03-13-at-3.57.00-AM-e1647327302406.jpeg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100%" }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/WhatsApp-Image-2022-03-13-at-3.56.22-AM-e1647327328869.jpeg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100%" }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/WhatsApp-Image-2022-03-03-at-6.00.27-AM-e1646629053562.jpeg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100%" }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-07-2-29-06-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide style={{}}>
          <div style={{ width: "100%" }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-07-2-49-38-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            maxWidth: "296px",
            minWidth: "200px",
          }}
        >
          <div style={{ width: "100%" }}>
            <img
              decoding="async"
              className="swiper-slide-image"
              src="assets/Photo-Sep-07-4-24-25-PM-min-scaled.jpg"
              alt="Photo Sep 03, 3 08 22 PM-min"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
