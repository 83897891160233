import React from "react";
import CustomSwiper from "../CustomSwiper";

const Work = () => {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-27e5e6a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="27e5e6a"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8100b37"
          data-id="8100b37"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
            <div
              className="elementor-element elementor-element-d228c48 elementor-widget elementor-widget-heading"
              data-id="d228c48"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Our Works
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-1c0dd75 elementor-widget elementor-widget-text-editor"
              data-id="1c0dd75"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>“World’s Leading Curb Painting Corporation”​</p>
              </div>
            </div>
            <div className="elementor-element elementor-element-fcdc54f elementor-arrows-position-inside elementor-pagination-position-outside elementor-widget elementor-widget-image-carousel e-widget-swiper">
              <CustomSwiper />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
