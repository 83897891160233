import React from "react";

const Welcome = () => {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-f54e512 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="f54e512"
      data-element_type="section"
      id="about"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ee3d613"
          data-id="ee3d613"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-7bdb06c elementor-widget elementor-widget-heading"
              data-id="7bdb06c"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Welcome to Curb'n IT
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-66856c5 elementor-widget elementor-widget-text-editor"
              data-id="66856c5"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>
                  Curb’n IT is an address curb-painting service to provide extra
                  security and safety to your beloved home. We pride ourselves
                  on the highest quality customer service and make sure each
                  home is well cared for. Not only do we want to keep your house
                  safe by having your house numbers painted on your curb, our
                  main goal is to teach individuals sales. To help others build
                  confidence and to refine communication skills is something you
                  will use for the rest of your life. We hope to help develop
                  people to become the best version of themselves. Curb’n IT is
                  currently offering its services to any city in the state of
                  Oregon.
                </p>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-9006647 elementor-widget elementor-widget-heading"
              data-id={9006647}
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Our Mission
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-b5c7864 elementor-widget elementor-widget-text-editor"
              data-id="b5c7864"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <div dir="ltr">
                  <p dir="ltr">
                    Student loan debt in the United States is at an all-time
                    high of 1.7 trillion dollars. The average student is
                    thirty-six thousand dollars in debt due to student loans.
                    Our CEO, Jimmy, had first-hand experience tackling his
                    student debt and came up with an idea: an address
                    curb-painting service that provides extra safety and
                    security to homeowners. But his main goal? To hire other
                    students and help curb their student loan debt through the
                    Curb’n IT mentality of prevalence and discipline.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-59273ac"
          data-id="59273ac"
          data-element_type="column"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-background-overlay" />
            <div
              className="elementor-element elementor-element-a454569 elementor-widget elementor-widget-image"
              data-id="a454569"
              data-element_type="widget"
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
                <img
                  decoding="async"
                  src="assets/logan-isbell-F5b7_OD4IMA-unsplash-683x1024.jpg"
                  className="attachment-large size-large wp-image-1153"
                  alt=""
                  loading="lazy"
                  srcSet="
              assets/logan-isbell-F5b7_OD4IMA-unsplash-683x1024.jpg   683w,
              assets/logan-isbell-F5b7_OD4IMA-unsplash-200x300.jpg    200w,
              assets/logan-isbell-F5b7_OD4IMA-unsplash-768x1152.jpg   768w,
              assets/logan-isbell-F5b7_OD4IMA-unsplash-1024x1536.jpg 1024w,
              assets/logan-isbell-F5b7_OD4IMA-unsplash-1365x2048.jpg 1365w,
              assets/logan-isbell-F5b7_OD4IMA-unsplash-scaled.jpg    1707w
            "
                  sizes="(max-width: 683px) 100vw, 683px"
                  width={683}
                  height={1024}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
