import React from "react";
import Header from "../Header";

const About = () => {
  return (
    <div>
      <Header />
      <div id="content" className="site-content">
        <div className="ast-container">
          <div
            data-elementor-type="wp-page"
            data-elementor-id={1321}
            className="elementor elementor-1321"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-yi77r18 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="yi77r18"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-no">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1e5e5f9"
                  data-id="1e5e5f9"
                  data-element_type="column"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-3e7fc59 elementor-widget elementor-widget-heading"
                      data-id="3e7fc59"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          About Us
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-024a506 elementor-widget elementor-widget-image"
                      data-id="024a506"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          decoding="async"
                          src="assets/Photo-Feb-20-4-23-27-PM-min-e1631431357895.png"
                          className="attachment-full size-full wp-image-1140"
                          alt="profile"
                          loading="lazy"
                          srcSet="
                      assets/Photo-Feb-20-4-23-27-PM-min-e1631431357895.png           1125w,
                      assets/Photo-Feb-20-4-23-27-PM-min-e1631431357895-209x300.png    209w,
                      assets/Photo-Feb-20-4-23-27-PM-min-e1631431357895-714x1024.png   714w,
                      assets/Photo-Feb-20-4-23-27-PM-min-e1631431357895-768x1101.png   768w,
                      assets/Photo-Feb-20-4-23-27-PM-min-e1631431357895-1071x1536.png 1071w
                    "
                          sizes="(max-width: 1125px) 100vw, 1125px"
                          width={1125}
                          height={1613}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-23633a0"
                  data-id="23633a0"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-b938e8f elementor-widget elementor-widget-heading"
                      data-id="b938e8f"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Chief Executive Officer
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-718ca85 elementor-widget elementor-widget-text-editor"
                      data-id="718ca85"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Jimmy Li is the Chief Executive Officer for Curb’n
                            IT. He founded Curb IT in November of 2020. In March
                            2021 he established his LLC, got licensed and
                            insured. Jimmy Li was born in Plymouth, Minnesota
                            but moved to Oregon at the young age of six. Jimmy
                            Li attended Lakeridge High school in Lake Oswego,
                            Oregon. In high school Jimmy Li was the Lakeridge
                            varsity men’s basketball team manager. He developed
                            leadership, communication, and management skills
                            through this position. In college Jimmy studied
                            business at the University of Oregon and discovered
                            his passion for sales when working for Cutco Cutlery
                            where he was awarded one of the top sales reps in
                            the Northwest region after his impressive feat of
                            doing over ten-thousand dollars in company revenue
                            in his first ten days.&nbsp;
                          </span>
                        </p>
                        <p>
                          Currently Jimmy Li oversees all the operations side of
                          the business and has a very “hands on” approach to
                          running the everyday activities of Curb’n IT. This
                          includes leading the sales team, handling public
                          relations, communicating with the marketing team,
                          maintaining customer relationships, hiring employees
                          and managing resources. He stays active on the job and
                          is always looking to improve company performances.
                          Jimmy Li is very focused and passionate about personal
                          growth and workforce development, always striving to
                          achieve the best version of yourself.
                        </p>
                        <p>
                          Jimmy Li’s education includes studies of business with
                          a focus on entrepreneurship. When not working Jimmy Li
                          likes to go to the gym, play basketball, spend quality
                          time with family and travelling.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-9d70e7c elementor-widget elementor-widget-image"
                      data-id="9d70e7c"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          decoding="async"
                          src="assets/jimmy-sign-white-1024x1024.png"
                          className="attachment-large size-large wp-image-1384"
                          alt=""
                          loading="lazy"
                          srcSet="
                      assets/jimmy-sign-white-1024x1024.png 1024w,
                      assets/jimmy-sign-white-300x300.png    300w,
                      assets/jimmy-sign-white-150x150.png    150w,
                      assets/jimmy-sign-white-768x768.png    768w,
                      assets/jimmy-sign-white.png           1080w
                    "
                          sizes="(max-width: 1024px) 100vw, 1024px"
                          width={1024}
                          height={1024}
                        />
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-fc3d166 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                      data-id="fc3d166"
                      data-element_type="widget"
                      data-widget_type="divider.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-divider">
                          <span className="elementor-divider-separator"> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* ast-container */}
      </div>
    </div>
  );
};

export default About;
