import React from "react";
import { useNavigate } from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import GiveUsChance from "./GiveUsChance";
import NeedJobToast from "./NeedJobToast";
import Services from "./Services";
import Testimonials from "./Testimonials";
import Welcome from "./Welcome";
import Work from "./Work";

const Hero = () => {
  const navigate = useNavigate();
  
  return (
    <div id="content" className="site-content">
      <div className="ast-container">
        <div id="primary" className="content-area primary">
          <main id="main" className="site-main">
            <article
              className="post-1081 page type-page status-publish ast-article-single"
              id="post-1081"
              itemType="https://schema.org/CreativeWork"
              itemScope="itemscope"
            >
              <header className="entry-header ast-header-without-markup" />
              <div className="entry-content clear" itemProp="text">
                <div
                  data-elementor-type="wp-post"
                  data-elementor-id={1081}
                  className="elementor elementor-1081"
                >
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-bf48392 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="bf48392"
                    data-element_type="section"
                    data-settings='{"background_background":"classic","shape_divider_bottom":"curve","shape_divider_bottom_negative":"yes"}'
                  >
                    <div className="elementor-background-overlay" />
                    <div
                      className="elementor-shape elementor-shape-bottom"
                      data-negative="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1000 100"
                        preserveAspectRatio="none"
                      >
                        <path
                          className="elementor-shape-fill"
                          d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
                        />
                      </svg>
                    </div>
                    <div className="elementor-container elementor-column-gap-no">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-959554b"
                        data-id="959554b"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-b72d633 elementor-widget elementor-widget-heading"
                            data-id="b72d633"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h1 className="elementor-heading-title elementor-size-default">
                                Curb'n IT
                              </h1>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-0b2e6e8 elementor-widget elementor-widget-heading"
                            data-id="0b2e6e8"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default">
                                "Take control of your day and crush your goals"
                              </h4>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-57c64ee elementor-widget elementor-widget-text-editor"
                            data-id="57c64ee"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container"></div>
                          </div>
                          <section
                            className="elementor-section elementor-inner-section elementor-element elementor-element-1c7c30a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="1c7c30a"
                            data-element_type="section"
                          >
                            <div className="elementor-container elementor-column-gap-default">
                              <div
                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-46ea59f"
                                data-id="46ea59f"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-element elementor-element-51cf2ec elementor-align-center elementor-widget elementor-widget-button"
                                    data-id="51cf2ec"
                                    data-element_type="widget"
                                    data-widget_type="button.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="elementor-button-wrapper">
                                        <a
                                          onClick={() =>
                                            navigate("/join-us-form")
                                          }
                                          className="elementor-button-link elementor-button elementor-size-sm"
                                          role="button"
                                        >
                                          <span className="elementor-button-content-wrapper">
                                            <span className="elementor-button-text">
                                              Join Us
                                            </span>
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* Components */}
                  <Welcome />
                  <GiveUsChance />
                  <About />
                  <Services />
                  <Work />
                  <NeedJobToast />
                  <Testimonials />
                  <Contact />
                </div>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Hero;
