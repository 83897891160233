import React from "react";

const Footer = () => {
  return (
    <>
      <footer
        className="site-footer"
        id="colophon"
        itemType="https://schema.org/WPFooter"
        itemScope="itemscope"
        itemID="#colophon"
      >
        <div
          className="site-primary-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-2-equal ast-builder-grid-row-tablet-2-equal ast-builder-grid-row-mobile-full ast-footer-row-stack ast-footer-row-tablet-stack ast-footer-row-mobile-stack"
          data-section="section-primary-footer-builder"
        >
          <div className="ast-builder-grid-row-container-inner">
            <div className="ast-builder-footer-grid-columns site-primary-footer-inner-wrap ast-builder-grid-row">
              <div className="site-footer-primary-section-1 site-footer-section site-footer-section-1">
                <aside
                  className="footer-widget-area widget-area site-footer-focus-item"
                  data-section="sidebar-widgets-footer-widget-1"
                  aria-label="Footer Widget 1"
                >
                  <div className="footer-widget-area-inner site-info-inner">
                    <section
                      id="block-10"
                      className="widget widget_block widget_media_image"
                    >
                      <figure className="wp-block-image size-large is-resized">
                        <img
                          decoding="async"
                          loading="lazy"
                          src="assets/white-version-1-1024x1024.png"
                          alt=""
                          className="wp-image-1505"
                          srcSet="
                            assets/white-version-1-1024x1024.png 1024w,
                            assets/white-version-1-300x300.png    300w,
                            assets/white-version-1-150x150.png    150w,
                            assets/white-version-1-768x768.png    768w,
                            assets/white-version-1-1536x1536.png 1536w,
                            assets/white-version-1-2048x2048.png 2048w,
                            assets/white-version-1-163x163.png    163w
                          "
                          sizes="(max-width: 136px) 100vw, 136px"
                          width="136"
                          height="136"
                        />
                      </figure>
                    </section>
                  </div>
                </aside>
              </div>
              <div className="site-footer-primary-section-2 site-footer-section site-footer-section-2">
                <div
                  className="ast-builder-layout-element ast-flex site-footer-focus-item"
                  data-section="section-fb-social-icons-1"
                >
                  <div className="ast-footer-social-1-wrap ast-footer-social-wrap">
                    <div className="footer-social-inner-wrap element-social-inner-wrap social-show-label-false ast-social-color-type-custom ast-social-stack-none ast-social-element-style-filled">
                      <a
                        href="https://www.facebook.com/jimmy.li.184"
                        aria-label="Facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ast-builder-social-element ast-inline-flex ast-facebook footer-social-item"
                      >
                        <span className="ahfb-svg-iconset ast-inline-flex svg-baseline">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
                          </svg>
                        </span>
                      </a>
                      <a
                        href="http://www.instagram.com/curbitLLC"
                        aria-label="Instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ast-builder-social-element ast-inline-flex ast-instagram footer-social-item"
                      >
                        <span className="ahfb-svg-iconset ast-inline-flex svg-baseline">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"></path>
                          </svg>
                        </span>
                      </a>
                      <a
                        href="mailto:JimmyLi@curbit.us"
                        aria-label="Email"
                        className="ast-builder-social-element ast-inline-flex ast-email footer-social-item"
                      >
                        <span className="ahfb-svg-iconset ast-inline-flex svg-baseline">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <title id="email">Email</title>
                            <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="site-below-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-full ast-builder-grid-row-tablet-full ast-builder-grid-row-mobile-full ast-footer-row-stack ast-footer-row-tablet-stack ast-footer-row-mobile-stack"
          data-section="section-below-footer-builder"
        >
          <div className="ast-builder-grid-row-container-inner">
            <div className="ast-builder-footer-grid-columns site-below-footer-inner-wrap ast-builder-grid-row">
              <div className="site-footer-below-section-1 site-footer-section site-footer-section-1">
                <div
                  className="ast-builder-layout-element ast-flex site-footer-focus-item ast-footer-copyright"
                  data-section="section-footer-builder"
                >
                  <div className="ast-footer-copyright">
                    <p>
                      Copyright © 2023 curbit.us |Designed by
                      <a href="http://smilefotilo.com/">Smile Fotilo</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
